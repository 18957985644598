import React, { useState, useEffect, lazy, Suspense } from 'react';
import { Flex, Text, IconButton, Button, Tabs, AlertDialog } from "@radix-ui/themes";
import { Separator } from "@radix-ui/themes";
import { AvatarDropdownMenu } from "components";
import { useNavigate } from 'react-router-dom';
import { useTheme } from "../../context/ThemeContext";
import { ArrowLeftIcon } from "@radix-ui/react-icons";
import { useLocations, useLiveAccessPoints, useGetWirelessLAN, useUpdateWirelessLAN } from 'hooks';
import { getProfile } from "api/UsersApi";

const AccessPoints = lazy(() => import('./AccessPoints'));
const Networks = lazy(() => import('./Networks'));
const GuestPortal = lazy(() => import('./GuestPortal'));

export default function GuestWifiSettings() {
  const navigate = useNavigate();
  const { setAppearance } = useTheme();
  
  const [selectedAppearance, setSelectedAppearance] = useState("Light");
  const handleAppearanceChange = (newAppearance) => {
    setSelectedAppearance(newAppearance);
    setAppearance(newAppearance.toLowerCase());
  };

  // Create states for user role and id for it to be accessible outside of the useEffect block
  const [userId, setUserId] = useState(null);
  const [organisationID, setOrganisationID] = useState(null);

  useEffect(() => {
    if (userId === null) {
      const fetchProfile = async () => {
        try {
          const profile = await getProfile();
          setUserId(profile.data.id);
          setOrganisationID(profile.data.organisation_id);
        } catch (error) {
          console.error("Error fetching profile:", error);
        } 
      };
      fetchProfile();
    } 
  }, [userId]);

  // Custom hooks to grab locations data and live access points status
  const { locationsData } = useLocations(userId);

  // Grabbing the WLAN Data using the useGetWirelessLAN hook
  const { data: wirelessLanData } = useGetWirelessLAN(organisationID);

  // Tab state variables
  const [currentTab, setCurrentTab] = useState('accesspoints');
  const [pendingTab, setPendingTab] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const [isDirtyMap, setIsDirtyMap] = useState({
    accesspoints: false,
    networks: false,
    guestportal: false,
  });

  // Custom hooks to grab locations data and live access points status
  const { liveAccessPointsStatus: liveAccessPointsStatus } = useLiveAccessPoints(locationsData, currentTab);

  const handleTabChange = (newTab) => {
    if (isDirtyMap[currentTab]) {
      setPendingTab(newTab);
      setAlertOpen(true);
    } else {
      setCurrentTab(newTab);
    }
  };

  const [handleBackClick, setHandleBackClick] = useState(false);
  useEffect(() => {
    if (handleBackClick) {
      setAlertOpen(true);
    }
  }, [handleBackClick]);

  const handleLeave = () => {
    if (handleBackClick) {
      setCurrentTab(null);
      setPendingTab(null);
      setHandleBackClick(false);
      setAlertOpen(false);
      navigate("/dashboard");
    }
    else {
      setIsDirtyMap((prev) => ({ ...prev, [currentTab]: false }));
      setCurrentTab(pendingTab);
      setPendingTab(null);
      setAlertOpen(false);
    }
  };

  const handleStay = () => {
    if (handleBackClick) {
      setHandleBackClick(false);
    }
    setPendingTab(null);
    setAlertOpen(false);
  };

  const handleIsDirtyChange = (tab, value) => {
    setIsDirtyMap((prev) => ({ ...prev, [tab]: value }));
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (Object.values(isDirtyMap).some((isDirty) => isDirty)) {
        event.preventDefault();
        event.returnValue = '';
      } else {
        localStorage.setItem('activeTab', currentTab);
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isDirtyMap, currentTab]);

  useEffect(() => {
    const savedTab = localStorage.getItem('activeTab');
    if (savedTab) {
      setCurrentTab(savedTab);
    }
  }, []);

  return (
    <React.Fragment>
      <Flex direction="column" style={{alignItems: 'flex-start', width: '100%', height: '100%'}}>
        {/* Guest WiFi Settings Header */}
        <Flex className='header' direction="column" height="var(--space-9)"
          style={{alignItems: 'center', alignSelf: 'stretch', backgroundColor: "var(--color-panel-solid)"}}>
          <Flex px="var(--space-5)" py="var(--space-4)" width="100%" height='100%'
            style={{alignItems: 'center', alignSelf: 'stretch', justifyContent: 'space-between'}}>
            <Flex direction="row"  gap="4" align="center">
              <IconButton size="1" variant="ghost" color="gray" 
                onClick={() => isDirtyMap[currentTab] ? setHandleBackClick(true) : navigate("/dashboard")}
                style={{cursor: 'pointer'}}>
                <ArrowLeftIcon />
              </IconButton>
              <Text className="guest-wifi-settings-header" size="4" weight="bold">
                Guest Wi-Fi Settings
              </Text>
            </Flex>
            <Flex align="center" gap="4">
              {/* <Button variant="surface" color="gray" style={{cursor: 'pointer'}}>
                <QuestionMarkCircledIcon />
                <Text>Wi-Fi Troubleshooting</Text>
              </Button> */}
              <Flex display="flex" align="center" gap="4">
                <AvatarDropdownMenu />
              </Flex>
            </Flex>
          </Flex>
          <Separator size="4" orientation="horizontal" />
        </Flex>
        {/* Tab Navigation */}
        <Tabs.Root value={currentTab} onValueChange={handleTabChange} style={{display: 'flex', 
          flexDirection: 'column', width: '100%', height: 'calc(100% - 64px)'}}>
          <Tabs.List size="2" style={{display: 'flex', padding: '0px var(--space-5)', minHeight: 'var(--space-7)',
            alignItems: 'center', backgroundColor: 'var(--color-panel-solid)'}}>
            <Tabs.Trigger value="accesspoints" style={{cursor: 'pointer'}}>Access Points</Tabs.Trigger>
            <Tabs.Trigger value="networks" style={{cursor: 'pointer'}}>Networks</Tabs.Trigger>
            <Tabs.Trigger value="guestportal" style={{cursor: 'pointer'}}>Guest Portal</Tabs.Trigger>
          </Tabs.List>
          
          <Flex pb="4" style={{alignSelf: 'stretch', justifyContent: 'center', width: '100%', height: 'calc(100% - 40px)'}}>
            <Tabs.Content style={{backgroundColor: "var(--color-page-background)" }} value="accesspoints">
              <Suspense>
                <AccessPoints liveAccessPointsStatus={liveAccessPointsStatus}/>
              </Suspense>
            </Tabs.Content>

            <Tabs.Content value="networks">
              <Suspense>
                <Networks wirelessLanData={wirelessLanData} organisationID={organisationID}
                onIsDirtyChange={(value) => handleIsDirtyChange('networks', value)}/>
              </Suspense>
            </Tabs.Content>

            <Tabs.Content value="guestportal" style={{width: '100%'}}>
              <Suspense>
                <GuestPortal organisationID={organisationID} 
                onIsDirtyChange={(value) => handleIsDirtyChange('guestportal', value)}/>
              </Suspense>
            </Tabs.Content>
          </Flex>
        </Tabs.Root>
      </Flex>
      <AlertDialog.Root open={alertOpen}>
        <AlertDialog.Content maxWidth="450px">
          <AlertDialog.Title> Leave Without Saving?</AlertDialog.Title>
          <AlertDialog.Description size="2">
            If you leave, your changes will not be saved.
          </AlertDialog.Description>
          <Flex gap="3" mt="4" justify="end">
            <AlertDialog.Cancel>
              <Button variant="soft" color="gray" onClick={handleStay}>Stay on Page</Button>
            </AlertDialog.Cancel>
            <AlertDialog.Action>
              <Button variant="solid" color="red" onClick={handleLeave}>Leave</Button>
            </AlertDialog.Action>
          </Flex>
        </AlertDialog.Content>
      </AlertDialog.Root>
    </React.Fragment>
  );
}
