import { useMutation } from "@tanstack/react-query";
import { attemptResendInviteUser } from '../../api/UsersApi';
import { useToast } from '../../context/ToastContext';

export const useResendInviteUser = () => {
  const { setToastMessage } = useToast();

  return useMutation({
    mutationKey: 'inviteUser',
    mutationFn: async (data) => attemptResendInviteUser(data),
    onMutate: async (data) => {
    },
    onSuccess: (data) => {
      setToastMessage("Invite resent");
    },
    onError: (error) => {
      setToastMessage("Resent failed")
    },
    onSettled: async(_, error) => {
      
    }
  });
};
