import React, { useEffect, useState } from 'react'
import { Flex, Text } from '@radix-ui/themes';
import * as Toast from "@radix-ui/react-toast";
import { useToast } from '../../context/ToastContext';
import './toastStyle.css';

export function ToastAlert() {
  const [open, setOpen] = useState(false);
  const { toastMessage, setToastMessage } = useToast();

  useEffect(() => {
    if (toastMessage) {
      setOpen(true);
    }
  }, [toastMessage]);

  return (
    <Toast.Provider swipeDirection='right' duration='5000'>
      <Toast.Root className='ToastRoot' open={open} onOpenChange={(isOpen) => {
        setOpen(isOpen);
        if (!isOpen) setToastMessage(null);
      }}
        style={{
          backgroundColor: 'var(--gray-a12)',
          justifyContent: 'center',
          borderRadius: 'var(--radius-2)',
        }}>
        <Toast.Description className='ToastDescription' asChild
        style={{
          color: 'var(--gray-contrast)',
        }}>
          <Flex px="3" py="2">
            <Text style={{color: 'var(--color-panel-solid)'}} size="2" weight="medium">{toastMessage}</Text>
          </Flex>
        </Toast.Description>
      </Toast.Root>
      <Toast.Viewport className='ToastViewport'/>
    </Toast.Provider>
  );
}
