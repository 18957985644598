import React from 'react';
import { Button, Flex, Text } from '@radix-ui/themes';
import { EyeOpenIcon, EyeClosedIcon } from '@radix-ui/react-icons';

export function LiveConnectionButton({ onClick, showLiveConnectionCards }) {

    return (
        <Flex gap="3" align="center">
            {showLiveConnectionCards ? (
                <Button style={{cursor: "pointer"}} onClick={onClick} variant="surface" color='gray'>
                    <EyeOpenIcon />
                    <Text style={{ color: 'inherit' }} weight="medium">
                        Live connections
                    </Text>
                </Button>
            ) : (
                <Button style={{cursor: "pointer"}} onClick={onClick} variant="surface" color='blue'>
                    <EyeClosedIcon />
                    <Text style={{ color: 'inherit' }} weight="medium">
                        Live connections
                    </Text>
                </Button>
            )}
        </Flex>
    );
}