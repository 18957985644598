import { useMutation } from "@tanstack/react-query";
import { attemptDeleteInviteUser } from '../../api/UsersApi';
import { useToast } from "../../context/ToastContext"

export const useDeleteInviteUser = () => {

  const { setToastMessage } = useToast();

  return useMutation({
    mutationKey: 'inviteUser',
    mutationFn: async (data) => attemptDeleteInviteUser(data),
    onMutate: async (data) => {
    },
    onSuccess: (data) => {
      setToastMessage("Invite cancelled")     
    },
    onError: (error) => {
      setToastMessage("Request failed")
    },
    onSettled: async(_, error) => {
      
    }
  });
};
