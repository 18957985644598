import { useState, useEffect, useMemo } from 'react';
import { calculateDateRange } from '../../helpers/metricsUtils';
import { useMetricsData } from 'hooks';

export const useWeekMetrics = (allLocations, userId, isLocationsSuccess) => {
  const [tabData, setTabData] = useState([]);

  useEffect(() => {
    const now = new Date();
    const hour = now.getHours();
    const dayOfWeek = now.getDay();

    const isMorning = hour < 11;
    const isMondayOrTuesday = dayOfWeek === 1 || dayOfWeek === 2;

    setTabData(isMondayOrTuesday ? ["Last Week", isMorning ? "Yesterday" : "Today"] : ["This Week", isMorning ? "Yesterday" : "Today"]);
  }, []);


  const tabOneDateRanges = useMemo(() => calculateDateRange(tabData[0]), [tabData]);
  const tabTwoDateRanges = useMemo(() => calculateDateRange(tabData[1]), [tabData]);

  const { currentPeriod: tabOneMainDateRange, previousPeriod: tabOneSubDateRange } = tabOneDateRanges;
  const { currentPeriod: tabTwoMainDateRange, previousPeriod: tabTwoSubDateRange } = tabTwoDateRanges;


  const tabOneMainConnectionsMetricsData = useMetricsData(tabOneMainDateRange, allLocations, userId, isLocationsSuccess);
  const tabOneSubConnectionsMetricsData = useMetricsData(tabOneSubDateRange, allLocations, userId, isLocationsSuccess);
  const tabTwoMainConnectionsMetricsData = useMetricsData(tabTwoMainDateRange, allLocations, userId, isLocationsSuccess);
  const tabTwoSubConnectionsMetricsData = useMetricsData(tabTwoSubDateRange, allLocations, userId, isLocationsSuccess);

  return {
    tabOneMainConnectionsMetricsData,
    tabOneSubConnectionsMetricsData,
    tabTwoMainConnectionsMetricsData,
    tabTwoSubConnectionsMetricsData,
  };
};