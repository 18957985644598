import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, Title, Tooltip, Legend, PointElement, Filler } from 'chart.js';
import { DateTime } from 'luxon';
import { Container } from "@radix-ui/themes";
import { useTheme } from "../../context/ThemeContext";
import './ChartColours.css';
import { Weight } from 'lucide-react';

ChartJS.register(CategoryScale, LinearScale, LineElement, Title, Tooltip, Legend, PointElement, Filler);

const getCSSVariable = (variable) => 
  getComputedStyle(document.documentElement).getPropertyValue(variable).trim();

export const GuestConnectionsByTimeOfDayChart = ({ data, startTimeMilliseconds, endTimeMilliseconds, timezone }) => {

  const { appearance } = useTheme();
  const [fontColor, setFontColor] = useState('');
  const [chartReturningColor, setChartReturningColor] = useState('');
  const [chartNewColor, setChartNewColor] = useState('');
  const [gridColour, setGridColour] = useState('');
  const [chartPointsBgColour, setChartPointsBgColour] = useState('');

  useEffect(() => {
    const fontColourLight = getCSSVariable('--chart-font-colour-light');
    const fontColourDark = getCSSVariable('--chart-font-colour-dark');
    const neutralColour = getCSSVariable('--chart-neutral-colour');
    const positiveColour = getCSSVariable('--chart-positive-colour');
    const gridColourLight = getCSSVariable('--chart-grid-colour-light');
    const gridColourDark = getCSSVariable('--chart-grid-colour-dark');
    const chartPointsBgColourLight = getCSSVariable('--chart-points-bg-colour-light');
    const chartPointsBgColourDark = getCSSVariable('--chart-points-bg-colour-dark');

    if (appearance === 'dark') {
      setFontColor(fontColourDark);
      setGridColour(gridColourDark);
      setChartPointsBgColour(chartPointsBgColourDark);
    } else {
      setFontColor(fontColourLight);
      setGridColour(gridColourLight);
      setChartPointsBgColour(chartPointsBgColourLight);
    }
    setChartReturningColor(neutralColour);
    setChartNewColor(positiveColour);
  }, [appearance]);

  const generateBins = (start, end, interval) => {
    const bins = [];
    let current = start;
    while (current < end) {
      bins.push([current, current + interval]);
      current += interval;
    }
    return bins;
  };

  const calculateInterval = () => {
    return 1000 * 60 * 60; // 1 hour  
  };
  
  const formatXAxisLabel = (date) => {
    const formattedDate = DateTime.fromMillis(date);
  
    if (!formattedDate.isValid) {
      console.error("Invalid date:", date);
      return "";
    }

    return formattedDate.toFormat('HH:mm'); // 1-hour format
  };

  const interval = calculateInterval(startTimeMilliseconds, endTimeMilliseconds);
  const bar_chart_bins = generateBins(startTimeMilliseconds, endTimeMilliseconds, interval);

  let processed_chart_data = [];

  if (data && data.chart && data.chart.length > 0) {
    // Initialize processed_chart_data with 24 bins for each hour of the day
    processed_chart_data = Array.from({ length: 24 }, (_, hour) => ({
      hour,
      new: 0,
      returning: 0,
      total: 0,
      average: 0,
      new_percent_change: 0,
      return_percent_change: 0,
    }));

    let chart_array = data.chart;

    for (let event of chart_array) {
      const eventStart = DateTime.fromMillis(event.connection_start_time).setZone(timezone);
      const eventEnd = DateTime.fromMillis(event.connection_end_time).setZone(timezone);

      let startHour = eventStart.hour;
      const endHour = eventEnd.hour;

      if (event.connection_start_time < startTimeMilliseconds) {
        startHour = 0;
      }

      if (event.guest_status === "returning") {
        for (let i = startHour; i <= endHour; i++) {
          processed_chart_data[i % 24].returning++;
        }
      } else {
        for (let i = startHour; i <= endHour; i++) {
          processed_chart_data[i % 24].new++;
        }
      }
    }

    const windowSize = 3;

    for (let i = 0; i < processed_chart_data.length; i++) {
      let sum = 0;
      let count = 0;

      processed_chart_data[i].total = processed_chart_data[i].new + processed_chart_data[i].returning;

      for (let j = i; j > i - windowSize && j >= 0; j--) {
        sum += processed_chart_data[j].total;
        count++;
      }

      processed_chart_data[i].average = Math.round(sum / count);
      processed_chart_data[i].new_percent = Math.round((processed_chart_data[i].new / processed_chart_data[i].total) * 100);
      processed_chart_data[i].return_percent = 100 - processed_chart_data[i].new_percent;
    }
  }

  const chartData = {
    labels: processed_chart_data.map((entry) => formatXAxisLabel(DateTime.fromObject({ hour: entry.hour }).toMillis())),
    datasets: [
      {
        label: 'New',
        data: processed_chart_data.map((entry) => entry.new),
        backgroundColor: chartNewColor,  // Set opacity for area fill
        borderColor: chartNewColor,  // Solid color for line
        fill: true,
        borderWidth: 1,
        tension: 0,
        pointBackgroundColor: chartPointsBgColour,  // Transparent background for points
      },
      {
        label: 'Returning',
        data: processed_chart_data.map((entry) => entry.returning),
        backgroundColor: chartReturningColor,
        borderColor: chartReturningColor,
        fill: true,
        borderWidth: 1,
        tension: 0,
        pointBackgroundColor: chartPointsBgColour,  // Transparent background for points
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        callbacks: {
          title: function (tooltipItems) {
            return tooltipItems[0].label;
          },
          footer: function (tooltipItems) {
            const dataset = tooltipItems[0].chart.data.datasets;
            const newValue = dataset[0].data[tooltipItems[0].dataIndex];
            const returningValue = dataset[1].data[tooltipItems[0].dataIndex];
            const total = newValue + returningValue;
            return `Total: ${total}`;
          },
          labelColor: function(context) {
            return {
              borderColor: context.dataset.borderColor,
              backgroundColor: context.dataset.borderColor, 
            };
          }
        },
        bodyFont: {
          family: 'Figtree',
        },
        titleFont: {
          family: 'Figtree',
        },
        footerFont: {
          family: 'Figtree',
        },
        itemSort: (a, b) => b.datasetIndex - a.datasetIndex, // Reverse the order of the tooltip items
      },
    },
    scales: {
      x: {
        ticks: {
          color: fontColor,
          font: {
            family: 'Figtree',
            size: 12,
          },
          padding: 8,
          callback: function(value, index, values) {
            if (bar_chart_bins.length > 17) {
              return index % 2 === 0 ? this.getLabelForValue(value) : '';
            } else {
              return this.getLabelForValue(value);
            }
          },
          maxRotation: 0,
          maxRotation: 0,
        },
        grid: {
          display: true,
          color: gridColour,
          borderWidth: 1,
          drawTicks: false,
        },
        border: {
          dash: [2, 2],
        }
      },
      y: {
        stacked: true,
        beginAtZero: true,
        position: 'right',
        ticks: {
          color: fontColor,
          font: {
            family: 'Figtree',
            size: 12,
          },
          padding: 8,
        },
        grid: {
          display: true,
          color: gridColour,
          borderWidth: 1,
          drawTicks: false,
        },
        border: {
          dash: [2, 2],
        }
      },
    },
  };

  return (
    <Line data={chartData} options={options}/>
  );
};