import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, Title, Tooltip, Legend, PointElement,  Filler } from 'chart.js';
import { DateTime } from 'luxon';
import { Container, Flex } from "@radix-ui/themes";
import { useTheme } from "../../context/ThemeContext";
import './ChartColours.css';
import { Weight } from 'lucide-react';

ChartJS.register(CategoryScale, LinearScale, LineElement, Title, Tooltip, Legend, PointElement, Filler);

const getCSSVariable = (variable) => 
  getComputedStyle(document.documentElement).getPropertyValue(variable).trim();

export const GuestConnectionsByMonthChart = ({ data, startTimeMilliseconds, endTimeMilliseconds, timezone }) => {

  const { appearance } = useTheme();
  const [fontColor, setFontColor] = useState('');
  const [chartReturningColor, setChartReturningColor] = useState('');
  const [chartNewColor, setChartNewColor] = useState('');
  const [gridColour, setGridColour] = useState('');
  const [chartPointsBgColour, setChartPointsBgColour] = useState('');

  useEffect(() => {
    const fontColourLight = getCSSVariable('--chart-font-colour-light');
    const fontColourDark = getCSSVariable('--chart-font-colour-dark');
    const neutralColour = getCSSVariable('--chart-neutral-colour');
    const positiveColour = getCSSVariable('--chart-positive-colour');
    const gridColourLight = getCSSVariable('--chart-grid-colour-light');
    const gridColourDark = getCSSVariable('--chart-grid-colour-dark');
    const chartPointsBgColourLight = getCSSVariable('--chart-points-bg-colour-light');
    const chartPointsBgColourDark = getCSSVariable('--chart-points-bg-colour-dark');

    if (appearance === 'dark') {
      setFontColor(fontColourDark);
      setGridColour(gridColourDark);
      setChartPointsBgColour(chartPointsBgColourDark);
    } else {
      setFontColor(fontColourLight);
      setGridColour(gridColourLight);
      setChartPointsBgColour(chartPointsBgColourLight);
    }
    setChartReturningColor(neutralColour);
    setChartNewColor(positiveColour);
  }, [appearance]);

  const calculateIntervals = (startTime, endTime) => {
    const intervals = [];
    let current = new Date(startTime);
  
    while (current < endTime) {
      const next = new Date(current.getFullYear(), current.getMonth() + 1, 1);
      intervals.push(next - current);
      current = next;
    }
  
    return intervals;
  };
  
  const generateBins = (start, end, intervals) => {
    const bins = [];
    let current = start;
    let intervalIndex = 0;
  
    while (current < end) {
      const interval = intervals[intervalIndex % intervals.length];
      bins.push([current, current + interval]);
      current += interval;
      intervalIndex++;
    }
  
    return bins;
  };
  
  const calculateInterval = (startTime, endTime) => {
    return calculateIntervals(startTime, endTime);
  };
  
  const formatXAxisLabel = (date) => {
    const formattedDate = DateTime.fromMillis(date);
  
    if (!formattedDate.isValid) {
      console.error("Invalid date:", date);
      return "";
    }
      return formattedDate.toFormat('MMM yyyy'); // 1-month format
  };

  const interval = calculateInterval(startTimeMilliseconds, endTimeMilliseconds);
  const bar_chart_bins = generateBins(startTimeMilliseconds, endTimeMilliseconds, interval);

  const findBinIndex = (bins, time) => {
    let low_index = 0;
    let high_index = bins.length - 1;

    while (low_index <= high_index) {
      const middle_index = Math.floor((low_index + high_index) / 2);
      const [lowerBound, upperBound] = bins[middle_index];

      if (lowerBound <= time && time < upperBound) {
        return middle_index;
      } else if (time < lowerBound) {
        high_index = middle_index - 1;
      } else {
        low_index = middle_index + 1;
      }
    }
    return -1;
  };

  let processed_chart_data = [];

  if (data && data.chart && data.chart.length > 0) {
    processed_chart_data = bar_chart_bins.map((entry) => ({
      start_date: entry[0],
      end_date: entry[1],
      new: 0,
      returning: 0,
      total: 0,
      average: 0,
      new_percent_change: 0,
      return_percent_change: 0,
    }));

    let chart_array = data.chart;

    for (let event of chart_array) {
      let start_index = findBinIndex(bar_chart_bins, event.connection_start_time);
      let end_index = findBinIndex(bar_chart_bins, event.connection_end_time);

      if (start_index !== -1 && end_index !== -1) {
        if (event.guest_status === "returning") {
          for (let i = start_index; i <= end_index; i++) {
            if (processed_chart_data[i]) {
              processed_chart_data[i].returning++;
            }
          }
        } else {
          for (let i = start_index; i <= end_index; i++) {
            if (processed_chart_data[i]) {
              processed_chart_data[i].new++;
            }
          }
        }
      }
    }

    const windowSize = 3;

    for (let i = 0; i < processed_chart_data.length; i++) {
      let sum = 0;
      let count = 0;

      processed_chart_data[i].total = processed_chart_data[i].new + processed_chart_data[i].returning;

      for (let j = i; j > i - windowSize && j >= 0; j--) {
        sum += processed_chart_data[j].total;
        count++;
      }

      processed_chart_data[i].average = Math.round(sum / count);
      processed_chart_data[i].new_percent = Math.round((processed_chart_data[i].new / processed_chart_data[i].total) * 100);
      processed_chart_data[i].return_percent = 100 - processed_chart_data[i].new_percent;
    }
  }

  const chartData = {
    labels: processed_chart_data.map((entry) => formatXAxisLabel(entry.start_date)),
    datasets: [
      {
        label: 'New',
        data: processed_chart_data.map((entry) => entry.new),
        backgroundColor: chartNewColor,  // Set opacity for area fill
        borderColor: chartNewColor,  // Solid color for line
        fill: true,
        borderWidth: 1,
        tension: 0,
        pointBackgroundColor: chartPointsBgColour,  // Transparent background for points
      },
      {
        label: 'Returning',
        data: processed_chart_data.map((entry) => entry.returning),
        backgroundColor: chartReturningColor,
        borderColor: chartReturningColor,
        fill: true,
        borderWidth: 1,
        tension: 0,
        pointBackgroundColor: chartPointsBgColour,  // Transparent background for points
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        callbacks: {
          title: function (tooltipItems) {
            return tooltipItems[0].label;
          },
          footer: function (tooltipItems) {
            const dataset = tooltipItems[0].chart.data.datasets;
            const newValue = dataset[0].data[tooltipItems[0].dataIndex];
            const returningValue = dataset[1].data[tooltipItems[0].dataIndex];
            const total = newValue + returningValue;
            return `Total: ${total}`;
          },
          labelColor: function(context) {
            return {
              borderColor: context.dataset.borderColor,
              backgroundColor: context.dataset.borderColor, 
            };
          }
        },
        bodyFont: {
          family: 'Figtree',
        },
        titleFont: {
          family: 'Figtree',
        },
        footerFont: {
          family: 'Figtree',
        },
        itemSort: (a, b) => b.datasetIndex - a.datasetIndex, // Reverse the order of the tooltip items
      },
    },
    scales: {
      x: {
        ticks: {
          color: fontColor,
          font: {
            family: 'Figtree',
            size: 12,
          },
          padding: 8,
          callback: function(value, index, values) {
            if (bar_chart_bins.length > 17) {
              return index % 2 === 0 ? this.getLabelForValue(value) : '';
            } else {
              return this.getLabelForValue(value);
            }
          },
          maxRotation: 0,
        },
        grid: {
          display: true,
          color: gridColour,
          borderWidth: 1,
          drawTicks: false,
        },
        border: {
          dash: [2, 2],
        }
      },
      y: {
        stacked: true,
        beginAtZero: true,
        position: 'right',
        ticks: {
          color: fontColor,
          font: {
            family: 'Figtree',
            size: 12,
          },
          padding: 8,
        },
        grid: {
          display: true,
          color: gridColour,
          borderWidth: 1,
          drawTicks: false,
        },
        border: {
          dash: [2, 2],
        }
      },
    },
  };

  return (
    <Line data={chartData} options={options}/>
  );
};