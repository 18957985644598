import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { useTheme } from "../../context/ThemeContext";
import './ChartColours.css';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const getCSSVariable = (variable) => 
  getComputedStyle(document.documentElement).getPropertyValue(variable).trim();

export const UniqueGuestsByGenderChart = ({ metricsData }) => {

  const { appearance } = useTheme();
  const [fontColor, setFontColor] = useState('');
  const [barColour, setBarColour] = useState('');
  const [gridColour, setGridColour] = useState('');

  useEffect(() => {
    const fontColourLight = getCSSVariable('--chart-font-colour-light');
    const fontColourDark = getCSSVariable('--chart-font-colour-dark');
    const barColour = getCSSVariable('--chart-bar-colour');
    const gridColourLight = getCSSVariable('--chart-grid-colour-light');
    const gridColourDark = getCSSVariable('--chart-grid-colour-dark');

    if (appearance === 'dark') {
      setFontColor(fontColourDark);
      setGridColour(gridColourDark);
    } else {
      setFontColor(fontColourLight);
      setGridColour(gridColourLight);
    }
    setBarColour(barColour);
  }, [appearance]);

  let processed_chart_data = [];

  if (metricsData && metricsData.connection_details && metricsData.connection_details.length > 0) {
    const genderGroups = ['Male', 'Female', 'Other', 'No Response'];
    processed_chart_data = genderGroups.map((group) => ({
      genderGroup: group,
      count: 0,
    }));

    const uniqueEmails = new Set();

    for (let entry of metricsData.connection_details) {
      if (!uniqueEmails.has(entry.email_address)) {
        uniqueEmails.add(entry.email_address);
        const gender = entry.gender;
        if (gender === "male") {
          processed_chart_data[0].count++;
        } else if (gender === "female") {
          processed_chart_data[1].count++;
        } else if (gender === "others") {
          processed_chart_data[2].count++;
        } else if (gender === "prefer not to respond") {
          processed_chart_data[3].count++;
        }
      }
  }
  }

  const chartData = {
    labels: processed_chart_data.map((entry) => entry.genderGroup),
    datasets: [
      {
        label: 'Unique Guests',
        data: processed_chart_data.map((entry) => entry.count),
        backgroundColor: barColour,
        borderRadius: 4,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        callbacks: {
          title: function (tooltipItems) {
            return tooltipItems[0].label;
          },
          labelColor: function(context) {
            return {
              borderColor: context.dataset.backgroundColor,
              backgroundColor: context.dataset.backgroundColor, 
            };
          }
        },
        bodyFont: {
          family: 'Figtree',
        },
        titleFont: {
          family: 'Figtree',
        },
        footerFont: {
          family: 'Figtree',
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: fontColor,
          font: {
            family: 'Figtree',
            size: 12,
          },
          padding: 8,
        },
        grid: {
          display: true,
          color: gridColour,
          borderWidth: 1,
          drawTicks: false,
        },
        border: {
          dash: [2, 2],
        }
      },
      y: {
        beginAtZero: true,
        position: 'right',
        ticks: {
          color: fontColor,
          font: {
            family: 'Figtree',
            size: 12,
          },
          padding: 8,
        },
        grid: {
          display: true,
          color: gridColour,
          borderWidth: 1,
          drawTicks: false,
        },
        border: {
          dash: [2, 2],
        }
      },
    },
  };

  return (
    <Bar data={chartData} options={options}/>
  );
};