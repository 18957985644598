import { useMutation, useQueryClient } from "@tanstack/react-query";
import { attemptEditOrgUser } from '../../api/OrgApi';
import { useToast } from '../../context/ToastContext';

export const useEditOrgUser = () => {
  const queryClient = useQueryClient();
  const { setToastMessage } = useToast();
  
  return useMutation({
    mutationKey: 'edit-org-user',
    mutationFn: ({ owner_id, data }) => attemptEditOrgUser(owner_id, data),
    onMutate: async ({ owner_id, data }) => {
      // Optional: Implement optimistic update logic here
    },
    onSuccess: (data, variables) => {
      const type = Object.keys(variables.data)[1];
      let message = "User Updated";
      if (type === "role") {
        message = "Role Updated";
      } else if (type === "locations") {
        message = "Location Access Updated";
      }
      setToastMessage(message)
      queryClient.invalidateQueries({
        queryKey: ["org-users"],
      });
    },
    onError: (error) => {
      setToastMessage("Update Failed")
    },
  });
};
