import * as React from "react";

const ToastContext = React.createContext();

export function ToastProvider({ children }) {
  const [toastMessage, setToastMessageState] = React.useState(null);

  const setToastMessage = (message) => {
    setToastMessageState(null); // Clear existing toast message
    setTimeout(() => {
      setToastMessageState(message);
    }, 0); // Set new message after clearing (allows re-render)
  };

  return (
    <ToastContext.Provider value={{ toastMessage, setToastMessage }}>
      {children}
    </ToastContext.Provider>
  )
}

export function useToast() {
  return React.useContext(ToastContext);
}