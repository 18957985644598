export const tabs = {
  "1-2": {
    "0-11": ["Last Week", "Yesterday"],
    "12-23": ["Last Week", "Today"]
  },
  "3-7": {
    "0-11": ["This Week", "Today"],
    "12-23": ["This Week", "Yesterday"]
  }
};

export function snackTextObject(data, week, day, praiseOne, praiseTwo) {
  const tabOneAverageConnections = Math.round(data.tabOne.busy.average * 10) / 10;
  const tabTwoAverageConnections = Math.round(data.tabTwo.busy.average * 10) / 10;

  const templates = {
    connections: {
      main: {
        "This Week": (
          data.tabOne.guestConnect.current !== 0 ?
          `This week, you saw *${data.tabOne.guestConnect.current} guest Wi-Fi connections* with ${data.tabOne.uniqueConnect.current} unique guests – that's ${praiseOne}!`
          : null
        ),
        "Last Week": (
          data.tabOne.guestConnect.current !== 0 ? 
          `Last week, you saw *${data.tabOne.guestConnect.current} guest Wi-Fi connections* with ${data.tabOne.uniqueConnect.current} unique guests – that's ${praiseOne}!`
          : null
        ),
        "Today": (
          data.tabTwo.guestConnect.current !== 0 ? 
          `Today so far, *${data.tabTwo.uniqueConnect.current} unique guests* connected to your guest Wi-Fi with ${data.tabTwo.guestConnect.current} guest connections – that's ${praiseOne}!`
          : null
        ),
        "Yesterday": (
          data.tabTwo.guestConnect.current !== 0 ? 
          `Yesterday, *${data.tabTwo.uniqueConnect.current} unique guests* connected to your guest Wi-Fi with ${data.tabTwo.guestConnect.current} guest connections – that's ${praiseOne}!`
          : null
        )
      },
      sub: {
        "This Week": (
          data.tabOne.guestConnect.previous !== 0 ?
          `The previous week saw ${data.tabOne.guestConnect.previous} guest connections with ${data.tabOne.uniqueConnect.previous} unique guests.`
          : null
        ),
        "Last Week": (
          data.tabOne.guestConnect.previous !== 0 ?
          `The previous week saw ${data.tabOne.guestConnect.previous} guest connections with ${data.tabOne.uniqueConnect.previous} unique guests.`
          : null
        ),
        "Today": (
          data.tabTwo.guestConnect.previous !== 0 ? 
          `The same day last week saw ${data.tabTwo.guestConnect.previous} guest connections with ${data.tabTwo.uniqueConnect.previous} unique guests.`
          : null
        ),
        "Yesterday": (data.tabTwo.guestConnect.previous !== 0 ? 
          `The same day last week saw ${data.tabTwo.guestConnect.previous} guest connections with ${data.tabTwo.uniqueConnect.previous} unique guests.`
          : null
        )
      }
    },
    demographic: {
      main: {
        "This Week": (
          data.tabOne.guestConnect.current !== 0 ? 
          `This week's largest demographic was *${data.tabOne.demo.largest}*.`
          : null
        ),
        "Last Week": (
          data.tabOne.guestConnect.current !== 0 ?
          `Last week's largest demographic was *${data.tabOne.demo.largest}*.`
          : null
        ),
        "Today": (
          data.tabTwo.guestConnect.current !== 0 ?
          `Today so far, your largest demographic is *${data.tabTwo.demo.largest}*.`
          : null
        ),
        "Yesterday": (
          data.tabTwo.guestConnect.current !== 0 ?
          `Your largest demographic yesterday was *${data.tabTwo.demo.largest}*.`
          : null
        ),
      },
      sub: {
        "This Week": (
          data.tabOne.guestConnect.current !== 0 ? (data.tabOne.demo.largest !== data.tabOne.demo.secondLargest ? `This group saw ${data.tabOne.demo.largestCount} unique guests connect. Your second largest demographic is ${data.tabOne.demo.secondLargest} with ${data.tabOne.demo.secondLargestCount} unique guests.`
          : `This group saw ${data.tabOne.demo.largestCount} unique guests connect.`)
          : null
        ),
        "Last Week": (
          data.tabOne.guestConnect.current !== 0 ? (data.tabOne.demo.largest !== data.tabOne.demo.secondLargest ? `This group saw ${data.tabOne.demo.largestCount} unique guests connect. Your second largest demographic is ${data.tabOne.demo.secondLargest} with ${data.tabOne.demo.secondLargestCount} unique guests.`
            : `This group saw ${data.tabOne.demo.largestCount} unique guests connect.`)
            : null
        ),
        "Today": (
          data.tabTwo.guestConnect.current !== 0 ? (data.tabTwo.demo.largest !== data.tabTwo.demo.secondLargest ? `This group saw ${data.tabTwo.demo.largestCount} unique guests connect. Your second largest demographic is ${data.tabTwo.demo.secondLargest} with ${data.tabTwo.demo.secondLargestCount} unique guests.`
          : `This group saw ${data.tabTwo.demo.largestCount} unique guests connect.`)
          : null
        ),
        "Yesterday": (
          data.tabTwo.guestConnect.current !== 0 ? (data.tabTwo.demo.largest !== data.tabTwo.demo.secondLargest ? `This group saw ${data.tabTwo.demo.largestCount} unique guests connect. Your second largest demographic was ${data.tabTwo.demo.secondLargest} with ${data.tabTwo.demo.secondLargestCount} unique guests.`
          : `This group saw ${data.tabTwo.demo.largestCount} unique guests connect.`)
          : null
        ),
      }
    },
    busiestPeriod: {
      main: {
        "This Week": (
          data.tabOne.guestConnect.current !== 0 ?
          `*${data.tabOne.busy.time}* was the busiest day, with ${data.tabOne.busy.unique < tabOneAverageConnections ? '' : praiseTwo} ${data.tabOne.busy.unique} unique guests connecting.`
          : null
        ),
        "Last Week": (
          data.tabOne.guestConnect.current !== 0 ?
          `*${data.tabOne.busy.time}* was the busiest day, with ${data.tabOne.busy.unique < tabOneAverageConnections ? '' : praiseTwo} ${data.tabOne.busy.unique} unique guests connecting.`
          : null
        ),
        "Today": (
          data.tabTwo.guestConnect.current !== 0 ?
          `*${data.tabTwo.busy.time}* has been the busiest time so far, with ${data.tabTwo.busy.unique < tabTwoAverageConnections ? '' : praiseTwo} ${data.tabTwo.busy.unique} unique guests connecting.`
          : null
        ),
        "Yesterday": (
          data.tabTwo.guestConnect.current !== 0 ?
          `*${data.tabTwo.busy.time}* saw the most connections yesterday, with ${data.tabTwo.busy.unique < tabTwoAverageConnections ? '' : praiseTwo} ${data.tabTwo.busy.unique} unique guests connecting.`
          : null
        ),
      },
      sub: {
        "This Week": (
          data.tabOne.guestConnect.current !== 0 ?
          `There's been an average of ${tabOneAverageConnections} connections per day.`
          : null
        ),
        "Last Week": (
          data.tabOne.guestConnect.current !== 0 ?
          `There was an average of ${tabOneAverageConnections} connections per day.`
          : null
        ),
        "Today": (
          data.tabTwo.guestConnect.current !== 0 ?
          `There's been an average of ${tabTwoAverageConnections} connections per hour.`
          : null
        ),
        "Yesterday": (
          data.tabTwo.guestConnect.current !== 0 ?
          `There was an average of ${tabTwoAverageConnections} connections per hour.`
          : null
        ),
      }
    }
  };

  const noDataMessage = (timePeriod) => `There are no guest connections for ${timePeriod}`;

  const text_one = [
    { main: templates.connections.main[week], sub: templates.connections.sub[week] },
    { main: templates.demographic.main[week], sub: templates.demographic.sub[week] },
    { main: templates.busiestPeriod.main[week], sub: templates.busiestPeriod.sub[week] }
  ];

  const text_two = [
    { main: templates.connections.main[day], sub: templates.connections.sub[day] },
    { main: templates.demographic.main[day], sub: templates.demographic.sub[day] },
    { main: templates.busiestPeriod.main[day], sub: templates.busiestPeriod.sub[day] }
  ];

  const allTextOneEmpty = text_one.every(text => text.main === null);
  const allTextTwoEmpty = text_two.every(text => text.main === null);

  return {
    text_one: allTextOneEmpty ? [{ main: noDataMessage(week), sub: null }] : text_one,
    text_two: allTextTwoEmpty ? [{ main: noDataMessage(day), sub: null }] : text_two
  };
}