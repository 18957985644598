/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useEffect } from 'react';
import { useGetAccessToken} from '../../hooks';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setLogout } from '../../features/auth/authSlice';

const AuthContext = createContext();

export default function AuthProvider({ children }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { authTokens } = useSelector(state => state.auth);
  const { mutate: getAccessToken } = useGetAccessToken();

 

  const handleLogout = () => {
    dispatch(setLogout());
    localStorage.clear();
    navigate('/login');
  };



  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');
    const accessTokenExpiration = localStorage.getItem('access_token_expiration');
    const refreshTokenExpiration = localStorage.getItem('refresh_token_expiration');

    const currentTime = new Date();
    if (new Date(refreshTokenExpiration) <= currentTime && refreshToken && accessToken) {
      handleLogout();
    } else if (new Date(accessTokenExpiration) <= currentTime && refreshToken) {
      getAccessToken({ refresh: refreshToken });
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = new Date();

      if (new Date(authTokens.refreshTokenExpiration) <= currentTime && authTokens.refreshToken && authTokens.accessToken) {
        handleLogout();
      } else if (new Date(authTokens.accessTokenExpiration) <= currentTime && authTokens.refreshToken) {
        getAccessToken({ refresh: authTokens.refreshToken });
      }
    }, 10000);

    return () => clearInterval(interval);
  }, [authTokens, getAccessToken, handleLogout]);

 

  return (
    <AuthContext.Provider value={{  handleLogout }}>
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext);
