import { createDropdownMenuScope } from '@radix-ui/react-dropdown-menu';
import { startOfWeek, subDays, endOfWeek, startOfDay, endOfDay } from 'date-fns';
import { DateTime } from 'luxon';

  // An object containing anonymous functions to process the date period.
  export function calculateDateRange(key) {
    const now = new Date();
    const yesterday = subDays(now, 1);
    const startOfCurrentWeek = startOfWeek(now, { weekStartsOn: 1 });

    const datePeriodCalculator = {
      "This Week": () => {
        const previousWeek = subDays(now, 7)
        const startOfPreviousWeek = startOfWeek(previousWeek, { weekStartsOn: 1 });
        const endOfPreviousWeek = endOfWeek(previousWeek, { weekStartsOn: 1 });

        return {
          currentPeriod: {
            from: startOfCurrentWeek,
            to: now
          },
          previousPeriod: {
            from: startOfPreviousWeek,
            to: endOfPreviousWeek
          }
        }
        
      },
      "Last Week": () => {
        const previousWeek = subDays(now, 7)
        const startOfPreviousWeek = startOfWeek(previousWeek, { weekStartsOn: 1 });
        const endOfPreviousWeek = endOfWeek(previousWeek, { weekStartsOn: 1 });

        // Fortnight referring to a period of two weeks
        const previousFortnight = subDays(now, 14);
        const startOfPreviousFortnight = startOfWeek(previousFortnight, { weekStartsOn: 1 });
        const endOfPreviousFortnight = endOfWeek(previousFortnight, { weekStartsOn: 1 });

        return {
          currentPeriod: {
            from: startOfPreviousWeek,
            to: endOfPreviousWeek
          },
          previousPeriod: {
            from: startOfPreviousFortnight,
            to: endOfPreviousFortnight
          }
        }
      },
      "Today": () => {
        const startOfToday = startOfDay(now);
        const endOfToday = endOfDay(now);
        const todayLastWeek = subDays(now, 7);
        const startOfTodayLastWeek = startOfDay(todayLastWeek);
        const endOfTodayLastWeek = endOfDay(todayLastWeek);
  
        return {
          currentPeriod: {
            from: startOfToday,
            to: endOfToday,
          },
          previousPeriod: {
            from: startOfTodayLastWeek,
            to: endOfTodayLastWeek
          }
        }
      },
      "Yesterday": () => {
        const startOfYesterday = startOfDay(yesterday);
        const endOfYesterday = endOfDay(yesterday);

        // Ereyesterday is the old English word for the day before yesterday
        // I have abbreviated it to Ereday.
        const yesterdayLastWeek = subDays(yesterday, 7);
        const startOfYesterdayLastWeek = startOfDay(yesterdayLastWeek);
        const endOfYesterdayLastWeek = endOfDay(yesterdayLastWeek);

        return {
          currentPeriod: {
            from: startOfYesterday,
            to: endOfYesterday
          },
          previousPeriod: {
            from: startOfYesterdayLastWeek,
            to: endOfYesterdayLastWeek
          }
        }
      },
    }

    if (typeof datePeriodCalculator[key] !== 'function') {
      return {
        currentPeriod: {
          from: null,
          to: null
        },
        previousPeriod: {
          from: null,
          to: null
        }
      };
    }
    return datePeriodCalculator[key]?.();
  }

  export const calculateHighestAndLowestGenderGroups = (metricsData) => {
    if (!metricsData || (Array.isArray(metricsData.connection_details) && metricsData.connection_details.length === 0)) {
      return {
        highestGender: "Unknown",
        highestGenderAgeGroup: "Unknown",
        highestGenderCount: 0,
        secondHighestGender: "Unknown",
        secondHighestGenderAgeGroup: "Unknown",
        secondHighestGenderCount: 0,
      };
    }
  
    const genderLabels = { "male": "Male", "female": "Female", "others": "Other", "prefer not to respond": "No Response" };
    const ageGroups = {
      "Under 18": "Under 18",
      "18-24": "18-24",
      "25-34": "25-34",
      "35-44": "35-44",
      "45-54": "45-54",
      "55-64": "55-64",
      "65+": "Above 65",
    };
    
    // Count occurrences
    const ageGenderCounts = {};
    const genderCounts = {};
    const ageCounts = {};
    const uniqueEmails = new Set();

    metricsData.connection_details.forEach((entry) => {
      if (!uniqueEmails.has(entry.email_address)) {
        uniqueEmails.add(entry.email_address);
        const key = `${entry.gender}-${entry.age}`;
        ageGenderCounts[key] = (ageGenderCounts[key] || 0) + 1;
        genderCounts[entry.gender] = (genderCounts[entry.gender] || 0) + 1;
        ageCounts[entry.age] = (ageCounts[entry.age] || 0) + 1;
      }
    });
    
    // Helper function to get highest or lowest count
    const getExtremeGroup = (counts, isHighest = true) => {
      return Object.keys(counts).reduce((a, b) => {
        if (counts[a] === counts[b]) {
          const [aGender, aAge] = a.split("-");
          const [bGender, bAge] = b.split("-");
          if (genderCounts[aGender] === genderCounts[bGender]) {
            return isHighest
              ? ageCounts[aAge] > ageCounts[bAge] ? a : b
              : ageCounts[aAge] < ageCounts[bAge] ? a : b;
          }
          return isHighest
            ? genderCounts[aGender] > genderCounts[bGender] ? a : b
            : genderCounts[aGender] < genderCounts[bGender] ? a : b;
        }
        return isHighest ? counts[a] > counts[b] ? a : b : counts[a] < counts[b] ? a : b;
      }, Object.keys(counts)[0]);
    };
  
    const highestAgeGenderGroup = getExtremeGroup(ageGenderCounts, true);
    const secondHighestAgeGenderGroup = getExtremeGroup(
      Object.fromEntries(Object.entries(ageGenderCounts).filter(([key]) => key !== highestAgeGenderGroup)),
      true
    );
    const [highestGender, ...highestAgeGroupParts] = highestAgeGenderGroup.split("-");
    const highestAgeGroup = highestAgeGroupParts.join("-");
    const [secondHighestGender, ...secondHighestAgeGroupParts] = secondHighestAgeGenderGroup ? secondHighestAgeGenderGroup.split("-") : highestAgeGenderGroup.split("-");
    const secondHighestAgeGroup = secondHighestAgeGroupParts.join("-");

    return {
      highestGender: genderLabels[highestGender],
      highestGenderAgeGroup: ageGroups[highestAgeGroup],
      highestGenderCount: ageGenderCounts[highestAgeGenderGroup],
      secondHighestGender: genderLabels[secondHighestGender],
      secondHighestGenderAgeGroup: ageGroups[secondHighestAgeGroup],
      secondHighestGenderCount: ageGenderCounts[secondHighestAgeGenderGroup],
    };
  };

export const getDateTimeConnectionsData = (insightsDayTimeData, type) => {
  if (!insightsDayTimeData || (Array.isArray(insightsDayTimeData?.chart) && insightsDayTimeData?.chart?.length === 0)) {
    return {
      highestConnectionsPeriod: "Unknown",
      totalConnectionsOnPeriod: 0,
      averageConnectionsPerPeriod: 0,
    };
  } else {
    let label;
    let processedDayTimeInsightsData;
    if (type === "week") {
      label = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
      processedDayTimeInsightsData = Array.from({ length: 7 }, (_, day) => ({
        day,
        new: 0,
        returning: 0,
        total: 0,
      }));
    } else if (type === "day") {
      label = [
        "12am", "1am", "2am", "3am", "4am", "5am", "6am", "7am", "8am", "9am", "10am", "11am",
        "12pm", "1pm", "2pm", "3pm", "4pm", "5pm", "6pm", "7pm", "8pm", "9pm", "10pm", "11pm"
      ];
      processedDayTimeInsightsData = Array.from({ length: 24 }, (_, hour) => ({
        hour,
        new: 0,
        returning: 0,
        total: 0,
      }));
    }

  if (insightsDayTimeData?.chart?.length > 0) {
    const insightsArray = insightsDayTimeData.chart;

      insightsArray.forEach((event) => {
        if (type === "week") {
          const eventStart = DateTime.fromMillis(event.connection_start_time);
          const dayOfWeek = eventStart.weekday - 1;

        if (event.guest_status === "returning") {
          processedDayTimeInsightsData[dayOfWeek].returning++;
        } else {
          processedDayTimeInsightsData[dayOfWeek].new++;
        }
      } else if (type === "day") {
        const eventStart = DateTime.fromMillis(event.connection_start_time);
        const eventEnd = DateTime.fromMillis(event.connection_end_time);
  
        const startHour = eventStart.hour;
        const endHour = eventEnd.hour;

        if (event.guest_status === "returning") {
          for (let i = startHour; i <= endHour; i++) {
            processedDayTimeInsightsData[i % 24].returning++;
          }
        } else {
          for (let i = startHour; i <= endHour; i++) {
            processedDayTimeInsightsData[i % 24].new++;
          }
        }
      } 
    });

      processedDayTimeInsightsData.forEach(data => {
        data.total = data.new + data.returning;
      });
    }

    const highestConnections = processedDayTimeInsightsData?.reduce(
      (max, data) => (data.total > max.total ? data : max),
      processedDayTimeInsightsData[0]
    );

    const totalConnections = processedDayTimeInsightsData?.reduce((sum, data) => sum + data.total, 0);
    const divisor = type === "week" ? 7 : 24;
    const averageConnectionsPerPeriod = (totalConnections / divisor ).toFixed(2);

    return {
      highestConnectionsPeriod: type === "week" ? label[highestConnections?.day] : label[highestConnections?.hour],
      totalConnectionsOnPeriod: highestConnections?.new,
      averageConnectionsPerPeriod,
    };
  }
};