import { useMutation } from "@tanstack/react-query";
import { attemptInviteUserUpdate } from '../../api/UsersApi';
import { useToast } from '../../context/ToastContext';

export const useUpdateUserInvite = () => {
  const { setToastMessage } = useToast();

  return useMutation({
    mutationKey: 'inviteUserUpdate',
    mutationFn: async (values) => attemptInviteUserUpdate(values),
    onMutate: async (data) => {
    },
    onSettled: async(_, error) => {
      
    }
  });
};
