"use client";

import React from "react";
import { ThemeProvider as NextThemesProvider } from "next-themes";

export function ShadThemeProvider(props) {
  const { children, ...rest } = props;
  return <NextThemesProvider {...rest}>{children}</NextThemesProvider>;
}

// This handles the theme for ShadCN components!