// hooks/useLocations.js
import { useState, useEffect } from 'react';
import { useGetOrgLocationsByUser } from '../app/useGetOrgLocationsByUser';

export const useLocations = (userId) => {
  const { data: locationsData, isSuccess: isLocationsSuccess } = useGetOrgLocationsByUser(userId);

  const [initialItems, setInitialItems] = useState([]);
  const [locationSelection, setLocationSelection] = useState([]);
  const [allLocations, setAllLocations] = useState([]);

  useEffect(() => {
    if (locationsData && locationsData.length > 0) {
      const initial_items = locationsData.map(location => ({ value: location.id, label: location.name }));
      const selected_items = locationsData.map(location => (location.id))
      setInitialItems(initial_items);
      setLocationSelection(selected_items); // Select all locations by default
      setAllLocations(selected_items);
    }
  }, [locationsData]);

  return {
    initialItems,
    locationSelection,
    setLocationSelection,
    locationsData,
    isLocationsSuccess,
    allLocations,
  };
};
