import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { attemptGetGuestPortalSettings } from 'api/OrgApi';
import { attemptUpdateGuestPortalSettings } from 'api/OrgApi';
import { useToast } from '../../context/ToastContext'

export const useGetOrgGuestPortalSettings = (orgId) => {
  return useQuery({
    queryKey: ['org-guest-portal-settings', orgId],
    enabled: !!orgId,
    queryFn: () => attemptGetGuestPortalSettings(orgId),
  });
}

// Updating the guest portal settings
export const useUpdateOrgGuestPortalSettings = () => {
  const queryClient = useQueryClient();
  const { setToastMessage } = useToast();

  return useMutation({
    mutationFn: ({ orgID, data }) => attemptUpdateGuestPortalSettings(orgID, data),
    onSuccess: () => {
      // Invalidate and refetch the guest portal settings to show updated data in the UI
      setToastMessage("Changes Saved");
      queryClient.invalidateQueries('org-guest-portal-settings');
    },
    onError: (error) => {
      setToastMessage("Update Failed");
    }
  });
}
