import { useMutation, useQueryClient } from "@tanstack/react-query";
import { attemptUpdateWirelessLAN } from '../../api/OrgApi';
import { useToast } from '../../context/ToastContext';

export const useUpdateWirelessLAN = () => {
  const queryClient = useQueryClient();
  const { setToastMessage } = useToast();
  
  return useMutation({
    mutationKey: 'update-wireless-lan',
    mutationFn: ({ organisationID, data }) => attemptUpdateWirelessLAN(organisationID, data),
    onMutate: async ({ organisationID, data }) => {
      // Optional: Implement optimistic update logic here
    },
    onSuccess: (variables) => {
      const request_type = variables.data.request_type;
      let message;
      if (request_type === "update_wlan") {
        message = "Changes Saved";
      } else if (request_type === "toggle_wlan") {
        const state = variables.data.enabled === true ? "Enabled" : "Disabled";
        message = `${variables.data.wlan_type} ${state}`
      }
      setToastMessage(message);
      queryClient.invalidateQueries({
        queryKey: ["org-users"],
      });
    },
    onError: (error) => {
      setToastMessage("Update Failed");
    },
  });
};