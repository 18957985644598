import React from 'react';
import { Flex, Text, Button } from "@radix-ui/themes";
import { useNavigate } from 'react-router-dom';

const SignUpSuccessful = () => {

    const navigate = useNavigate();

    const handleSignInClick = () => {
      navigate('/login');
    };


  return (
    <React.Fragment>
      <Flex p="5" direction="column" gap="5" height="100%"
          style={{alignSelf: 'stretch', alignItems: 'center', flex: '1 0 0', justifyContent: 'center'}}>
          <Flex direction="column" gap="2" style={{alignItems: 'center'}}>
              <Text size="5" weight="medium">
                Successfully Signed Up
              </Text>
              <Text size="2" weight="regular" color="gray" style={{width:'296px', textAlign:'center'}}>
                Welcome to b connect.
              </Text>
              </Flex>
              <Flex direction="column" gap="8" style={{alignItems: 'center'}}>
              <Button 
                  type="submit" 
                  size="2"
                  color="blue"
                  onClick={handleSignInClick}
                  variant='solid'
                  
                >
                  Sign In
              </Button>
              
          </Flex>
      </Flex>
    </React.Fragment>
  );
};

export default SignUpSuccessful;